<!--
 * @Description: 服务单管理
 * @Author: ChenXueLin
 * @Date: 2021-08-17 10:32:32
 * @LastEditTime: 2022-07-15 16:18:24
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="serviceNo">
              <el-input
                v-model="searchForm.serviceNo"
                placeholder="服务单号"
                title="服务单号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpId">
              <e6-vr-select
                v-model="searchForm.corpId"
                :data="corpList"
                placeholder="客户名称"
                title="客户名称"
                clearable
                virtual
                remote
                :is-title="true"
                @filterChange="handleLoadCorpFilter"
                :props="{
                  id: 'corpId',
                  label: 'corpName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="tag">
              <e6-vr-select
                v-model="searchForm.tag"
                :data="workTagsList"
                placeholder="标签选择"
                title="标签选择"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="serviceType">
              <e6-vr-select
                v-model="searchForm.serviceType"
                :data="taskTypeList"
                placeholder="服务单类型"
                title="服务单类型"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="serviceStatus">
              <e6-vr-select
                v-model="searchForm.serviceStatus"
                :data="workStatusList"
                placeholder="服务单状态"
                title="服务单状态"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="createdBy">
              <el-input
                placeholder="创建人"
                v-model="searchForm.createdBy"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="nowHandleBy">
              <el-input
                placeholder="当前处理人"
                v-model="searchForm.nowHandleBy"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--2" prop="expectTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="要求完成时间"
                ref="effectiveDatePicker"
                v-model="searchForm.expectTime"
                title="要求完成时间"
                :picker-options="pickerOptions('searchForm.expectTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="要求完成时间（始）"
                end-placeholder="要求完成时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="finishTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="工单完成时间"
                ref="effectiveDatePicker"
                v-model="searchForm.finishTime"
                title="工单完成时间"
                :picker-options="pickerOptions('searchForm.finishTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="工单完成时间（始）"
                end-placeholder="工单完成时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-history" title="历史记录" @click="toRecord"></i>
          <i
            class="e6-icon-card-pluck"
            title="批量转单"
            @click="changeTask"
          ></i>
          <i
            class="e6-icon-prohibit_line"
            title="批量关闭工单"
            @click="closeTask"
          ></i>
          <i class="e6-icon-add_line" title="创建工单" @click="createTask"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          @selection-change="handleSelectionChange"
          ref="elTable"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <div
                class="table-label"
                v-if="column.fieldName === 'tags' && row[column.fieldName]"
              >
                <span
                  class="label-box"
                  v-for="(item, index) in row.tags"
                  :key="index"
                >
                  {{ item }}
                </span>
              </div>
              <span
                v-else-if="column.fieldName === 'serviceStatus'"
                :class="[
                  'status',
                  row.serviceStatusCode == 3 || row.serviceStatusCode == 4
                    ? 'finish'
                    : row.serviceStatusCode === 2
                    ? 'ongoing'
                    : ''
                ]"
              >
                {{ row[column.fieldName] }}
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 创建工单弹框 -->
    <add-task-dialog
      :showAdd="showAdd"
      @closeDialog="handleClose"
    ></add-task-dialog>
    <!-- 关闭工单弹框 -->
    <close-task-dialog
      :closeVisible="closeVisible"
      :ignoreCount="ignoreCount"
      :closeCount="closeCount"
      @handleClose="handleClose"
      @confirm="closeTaskReq"
    ></close-task-dialog>
    <!-- 批量转单弹框 -->
    <el-dialog
      v-dialogDrag
      title="批量转单"
      :visible.sync="changeVisible"
      width="500px"
      :before-close="
        () => {
          handleClose('changeVisible');
        }
      "
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      custom-class="change-dialog"
    >
      <div class="content">
        <e6-title>
          <div slot="tooltip">
            <i class="e6-icon-tips_line"></i>
            忽略 {{ ignoreNum }} 个任务单
          </div>
        </e6-title>
        <div class="title">已选中{{ selectNum }}个工单</div>
        <el-form
          :rules="changeFormRules"
          ref="changeForm"
          class="changeForm"
          :model="changeForm"
          label-width="100px"
          :inline="true"
        >
          <el-form-item label="是否是部门" prop="isDept">
            <el-radio-group v-model="changeForm.isDept">
              <el-radio :label="1">部门</el-radio>
              <el-radio :label="0">员工</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="changeForm.isDept == 1"
            label="指派部门"
            prop="departmentId"
          >
            <!-- 部门下拉框 -->
            <e6-select
              v-model="changeForm.departmentId"
              :dataList="treeData"
              :filterable="true"
              :slotTemplate="'tree'"
              :multiple="false"
              :issingleleaf="false"
              ref="t_select"
            ></e6-select>
          </el-form-item>
          <el-form-item
            label="指派处理人"
            prop="solveRpid"
            v-if="changeForm.isDept == 0"
          >
            <all-user-search
              v-model="changeForm.solveRpid"
              clear
              placeholder="指派处理人"
              title="指派处理人"
              :propsConfig="{
                id: 'employeeId',
                label: 'userName'
              }"
            >
            </all-user-search>
          </el-form-item>
        </el-form>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose('changeVisible')"
          >取消</el-button
        >
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import addTaskDialog from "@/components/workOrderManage/addTaskDialog";
import {
  getServiceList,
  closeService,
  closeConfirm,
  changeServiceTask,
  findDownList,
  getFrameworkTree
} from "@/api";
import allUserSearch from "@/components/allUserSearch";
import { printError } from "@/utils/util";
import CloseTaskDialog from "@/components/workOrderManage/closeTaskDialog.vue";
export default {
  name: "serviceListManage",
  data() {
    return {
      loading: false,
      showAdd: false, //是否显示创建工单弹框
      workTagsList: [], //标签下拉框
      taskTypeList: [], //工单类型
      workStatusList: [], //服务单状态
      corpList: [], //客户名称下拉框
      searchForm: {
        serviceNo: "", //服务单号
        corpId: "", //客户名称
        tag: "", //标签
        serviceType: "", //服务单类型
        serviceStatus: "", //服务单状态
        createdBy: "", //创建人
        nowHandleBy: "", //当前处理人
        expectTime: [],
        expectStartTime: "", //要求完成时间开始时间
        expectEndTime: "", //要求完成时间结束时间
        finishTime: [],
        finishStartTime: "", //工单完成时间开始时间
        finishEndTime: "", //工单完成时间结束时间
        createTime: [],
        createdStartTime: "", //	创建时间开始时间
        createdEndTime: "", //	创建时间结束时间
        pageIndex: 1,
        pageSize: 20
      },
      total: 0,
      columnData: [
        {
          fieldName: "tags",
          display: true,
          fieldLabel: "标签",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "serviceNo",
          display: true,
          fieldLabel: "服务单号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "客户名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpProp",
          display: true,
          fieldLabel: "客户属性",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "mainPart",
          display: true,
          fieldLabel: "所属主体",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "serviceType",
          display: true,
          fieldLabel: "服务单类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "serviceStatus",
          display: true,
          fieldLabel: "服务单状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          mode: "color",
          color: "#79a3f2"
        },
        {
          fieldName: "expectTimeStr",
          display: true,
          fieldLabel: "要求完成时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "finishTimeStr",
          display: true,
          fieldLabel: "工单完成时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "nowHandleBy",
          display: true,
          fieldLabel: "当前处理人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdBy",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTimeStr",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [], // 表格数据
      /********转单***********/
      changeVisible: false,
      treeData: [],
      changeForm: { isDept: 1, departmentId: [], solveRpid: "" },
      ignoreNum: 0, //忽略个数
      selectNum: 0, //可操作个数
      /******关闭工单********/
      selColumn: [], //勾选的数据集合
      closeVisible: false,
      ignoreCount: 0, //忽略数据数
      closeCount: 0 //可关闭数据数
    };
  },
  mixins: [listPage, listPageReszie, base],
  components: {
    addTaskDialog,
    CloseTaskDialog,
    allUserSearch
  },
  computed: {
    changeFormRules() {
      let rules;
      if (this.changeForm.isDept == 1) {
        rules = {
          departmentId: [
            {
              required: true,
              message: "请选择部门",
              trigger: ["blur", "change"]
            }
          ]
        };
      } else {
        rules = {
          solveRpid: [
            {
              required: true,
              message: "请选择指派人员",
              trigger: ["blur", "change"]
            }
          ]
        };
      }
      return rules;
    }
  },
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  watch: {
    // 要求完成时间
    "searchForm.expectTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.expectTime = this.searchForm.expectTime.reverse();
        }
        this.searchForm.expectStartTime = val[0] || "";
        this.searchForm.expectEndTime = val[1] || "";
      }
    },
    //创建时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.createdStartTime = val[0] || "";
        this.searchForm.createdEndTime = val[1] || "";
      }
    },
    //创建时间
    "searchForm.finishTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.finishTime = this.searchForm.finishTime.reverse();
        }
        this.searchForm.finishStartTime = val[0] || "";
        this.searchForm.finishEndTime = val[1] || "";
      }
    }
  },
  methods: {
    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        this.searchForm.corpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    //全部员下拉框处理逻辑
    handleLoadEmployee: _.debounce(async function(val) {
      console.log(val, "val===");
      if (val) {
        this.loadEmployeeData(val);
      }
    }, 300),

    // 初始化数据(获取表头,表格数据)
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [
          findDownList(["workTags", "serviceSecondClass", "workStatus"]),
          getFrameworkTree()
        ];
        let [taskTypeRes, departmentRes] = await Promise.all(promiseList);
        //标签下拉框
        this.workTagsList = this.getFreezeResponse(taskTypeRes, {
          path: "data.workTags"
        });
        //任务单类型
        this.taskTypeList = this.getFreezeResponse(taskTypeRes, {
          path: "data.serviceSecondClass"
        });
        //工单状态
        this.workStatusList = this.getFreezeResponse(taskTypeRes, {
          path: "data.workStatus"
        });
        //部门
        this.handleTreeData(departmentRes.data.children);
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    // 日志
    toRecord() {
      this.$router.push({
        name: "serviceTaskRecord",
        params: {
          refresh: true
        },
        query: {}
      });
    },
    //处理树形结构
    handleTreeData(data) {
      // 第二种 修改涉及级联选择器 最后一个空白的情况
      // 循环遍历json数据
      for (var i = 0; i < data.length; i++) {
        data[i]["label"] = data[i].name;
        if (data[i].children && data[i].children.length) {
          this.handleTreeData(data[i].children);
        }
      }
      this.treeData = data;
    },
    //获取服务单列表
    async queryList() {
      try {
        this.loading = true;
        let res = await getServiceList(this.searchForm);
        let tableData = res.data.array;
        tableData.map(item => {
          item.tags = item.tags ? item.tags.split(",") : [];
        });
        this.tableData = tableData;
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //选中数据
    handleSelectionChange(columns) {
      this.selColumn = columns;
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.routerPush({
          name: "serviceListManage/serviceDetail",
          params: {
            refresh: true,
            serviceId: row.serviceId
          }
        });
      }
    },
    //创建工单
    createTask() {
      this.showAdd = true;
    },
    /********批量关闭操作************/
    //点击批量关闭任务单
    closeTask() {
      if (!this.selColumn.length) {
        this.$message.warning("请先勾选要关闭的任务单");
        return;
      }
      this.closeTaskValidReq();
    },
    //关闭工单检验
    async closeTaskValidReq() {
      try {
        let parmas = this.selColumn.map(item => {
          return item.serviceId;
        });
        let res = await closeConfirm({ serviceIds: parmas });
        this.closeVisible = true;
        this.ignoreCount = res.data.unTaskIdList.length; //不能关闭的单
        this.unTaskIdList = res.data.unTaskIdList;
        this.closeCount = res.data.taskIdList.length; //能关闭的单
        this.taskIdList = res.data.taskIdList;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击弹框确定
    handleSubmit() {
      this.closeTaskReq();
    },
    //关闭工单请求
    async closeTaskReq() {
      try {
        let res = await closeService({ serviceIds: this.taskIdList });
        if (res.code == "OK") {
          this.$message.success("关闭成功");
          this.queryList();
          this.handleClose("closeVisible");
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    /********批量转单操作*********/
    //点击批量转单
    changeTask() {
      if (!this.selColumn.length) {
        this.$message.warning("请先勾选要关闭的任务单");
        return;
      }
      this.changeVisible = true;
      this.ignoreNum = this.selColumn.filter(item => {
        return item.serviceStatusCode == 3 || item.serviceStatusCode == 4;
      }).length;
      this.selectNum = this.selColumn.filter(item => {
        return item.serviceStatusCode == 1 || item.serviceStatusCode == 2;
      }).length;
    },
    handleConfirm() {
      this.$refs.changeForm.validate(valid => {
        if (valid) {
          this.changeTaskReq();
        }
      });
    },
    //批量转单请求
    async changeTaskReq() {
      try {
        let ids = this.selColumn.map(item => {
          return item.serviceId;
        });
        let res = await changeServiceTask({
          serviceIds: ids,
          isDept: this.changeForm.isDept,
          handleBy:
            this.changeForm.isDept == 1
              ? this.changeForm.departmentId[0]
              : this.changeForm.solveRpid
        });
        if (res.code == "OK") {
          this.$message.success("转单成功");
          this.handleClose("changeVisible");
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //关闭弹框
    handleClose(name) {
      this[name] = false;
      this.unTaskIdList = [];
      this.ignoreCount = 0;
      this.taskIdList = [];
      this.closeCount = 0;
      if (name == "changeVisible") {
        this.changeForm = { isDept: 1, departmentId: [], solveRpid: "" };
        this.$refs.changeForm.resetFields();
        this.changeVisible = false;
        this.ignoreNum = 0;
        this.selectNum = 0;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.table-label {
  .label-box {
    padding: 0px 10px;
    border-radius: 20px;
    display: inline-block;
    border: 1px solid #46bfea;
    color: #46bfea;
    margin: 5px;
  }
}

.change-dialog {
  .content {
    text-align: center;
    .title {
      margin-top: 10px;
      margin-bottom: 15px;
    }
    .changeForm {
      margin-bottom: 20px;
    }
  }
}
</style>
